import Layout from "../components/Layout";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import DeleteModal from "../components/DeleteModal";
import EditPublishedVideoModal from "../components/Modals/EditPublishedVideoModal";
import ManualUploadModal from "../components/Modals/ManualUploadModal";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import * as moment from "moment";
import axiosInstance from '../services/api'; // Adjust the path based on your file structure
import Loader from "../components/Loader/index";
import ConfirmationModal from '../components/Modals/ConfirmationModal';

function Published() {
    const navigate = useNavigate();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isManualModalOpen, setManualModelOpen] = useState(false);
    const [vidIdx, setVidIdx] = useState(-1);
    const [isUtilize, setUtilize] = useState(true);
    const [videosCount, setVideosCount] = useState(0);
    const [vendors, setVendors] = useState([]);
    const [teleweelers, setTeleweelers] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [currentVideo, setCurrentVideo] = useState({
        user: "",
        video_link: "",
        facebook_video_id: "",
        category: "",
        category: "",
        facebook_channel: "",
        instagranVideoId:""
    });
    

    const [instagranVideoId, setInstaVideoId] = useState('');
    const [selectedTeleweeler, setSelectedTeleweeler] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);





    useEffect(() => {
        getTeleweelers();
        getPublishedVideos();
        // getVendors()
    }, [selectedTeleweeler]);

    async function getTeleweelers() {
        const response = await axiosInstance.get('https://scrapper.telewee.com/teleweelers');
        setTeleweelers(response.data);
    }

    async function getVendors() {
        const response = await axiosInstance.get('https://api.telewee.com/vendors');
        setVendors(response.data);
    }

    async function getPublishedVideos() {
        try {
            // let nextPage = page;
            let response;

            setIsLoading(true);
            // alert(selectedTeleweeler)

            // Update API URL based on selected teleweeler
            const url = selectedTeleweeler
                ? `https://scrapper.telewee.com/videos?teleweeler_name=${selectedTeleweeler}`
                : `https://scrapper.telewee.com/videos`;

            response = await axiosInstance.get(url);
            // setVideos((prevRecords) => [...prevRecords, ...response.data]);
            setVideos(response.data['videos']);
            setVideosCount(response.data['count']);
            // nextPage += 1;
            // setPage(nextPage);
            setIsLoading(false);
            // setVideosCount(response.data.length);

        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }

    async function deleteItem(e, videoId) {
        if(e)
        e.preventDefault();

        const response = await axiosInstance.post(`https://scrapper.telewee.com/videos/${videoId}/delete`);
        // alert("Video has been ignored!");
        getPublishedVideos();
    }

    async function submitVideo(e) {
        // e.preventDefault()
        try {
            if (currentVideo.video_link && currentVideo.user && currentVideo.facebook_channel && currentVideo.facebook_video_id) {
                const response = await axiosInstance.post('https://api.telewee.com/videos/manual', currentVideo);
                alert("Video has been sent to pending");
                setManualModelOpen(false);

                setTimeout(() => {
                    deleteItem(e,instagranVideoId)
                }, 200);

            } else {
                alert("You should select a video!");
            }
        }
        catch (err) {
            alert("Telweeler ID is wrong or something went wrong");
        }
    }




    const handleConfirm = async() => {
        // alert(JSON.stringify(currentVideo));
        // Additional logic for acceptance can go here

        try {
            if (currentVideo.video_link && currentVideo.user ) {
                const response = await axiosInstance.post('https://api.telewee.com/videos/accept_to_pending', currentVideo);
                alert("Video has been sent to pending");
                setManualModelOpen(false);

                setTimeout(() => {
                    deleteItem(null,currentVideo.instagranVideoId)
                }, 200);

            } else {
                alert("You should select a video!");
            }
        }
        catch (err) {
            alert("Telweeler ID is wrong or something went wrong");
        }
      };
    
 



    return (
        <div>
            <Layout>
                <div class="pl-4 pr-4 sm:ml-64">

                    <div class="max-w-full mx-4 py-6 sm:mx-auto">
                        <div class="sm:flex sm:space-x-4">
                            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                                <div class="bg-white p-5">
                                    <div class="sm:flex sm:items-start">
                                        <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                            <h3 class="text-sm leading-6 font-medium text-gray-600">Total Videos Published</h3>
                                            <p class="text-3xl font-bold text-black">{videosCount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="mb-4 p-2  w-[400px]">
                        <label htmlFor="teleweeler-dropdown" className="block text-gray-700">Select Teleweeler:</label>
                        <select
                            id="teleweeler-dropdown"
                            value={selectedTeleweeler}
                            onChange={(e) => setSelectedTeleweeler(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option value="">All Teleweelers</option>
                            {teleweelers.map((teleweeler) => (
                                <option key={teleweeler.name} value={teleweeler.name}>
                                    {teleweeler.name}
                                </option>
                            ))}
                        </select>
                    </div>





                    <div class="relative overflow-x-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                            {videos.map((video, index) => (
                                <div
                                    key={index}
                                    className="bg-white shadow-md rounded-lg overflow-hidden"
                                >
                                    <div className="w-50 h-80">
                                        <video
                                            src={video.video}
                                            controls
                                            className="object-cover w-full h-full"
                                        />
                                    </div>
                                    <div className="p-4">
                                    <p className="text-gray-600 my-1">
                                    Caption: <br/>
                                        {video.caption}</p>
                                        <p className="text-gray-800  my-1">{video.teleweeler_name}</p>
                                        <a className="text-blue-500  my-1" href={"https://www.instagram.com/reel/" + video.code}>Instagram Reel Link</a>
                                        <p className="text-gray-600 text-sm my-1">
                                            Published on: <br/>
                                            {moment.unix(video.published_on).format('MMMM D, YYYY, h:mm:ss A')}</p>
                                        {/* <p className="text-gray-600 text-sm">{new Date().toLocaleDateString()}</p> */}



                                        <button
                                                    onClick={((e) => {
                                                        // setInstaVideoId(video._id)
                                                        
                                                        setCurrentVideo({
                                                            instagranVideoId:video._id,
                                                            user: video.teleweeler_id,
                                                            video_link: video.video,
                                                            facebook_video_id: "",
                                                            category: "",
                                                            category: "",
                                                            facebook_channel: ""
                                                        })
                                                        setIsModalOpen(true)

                                                        // setUtilize(false)
                                                        // setVidIdx(index)
                                                    })}
                                                    className="text-white text-sm bg-green-500 p-2 my-4 rounded">Accept</button>

                                       

                                        <button onClick={((e) => {
                                            deleteItem(e, video._id)
                                        })} className="text-white text-sm bg-red-500 p-2 my-4  mx-2 rounded">Ignore</button>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>



                </div>


                {/* <EditPublishedVideoModal

                    open={isEditModalOpen && currentVideo != {}}
                    setOpen={setIsEditModalOpen}
                    editFunc={editItem}
                    currentVideo={currentVideo}
                    setCurrentVideo={setCurrentVideo}
                    vendors={vendors}

                    setB2BEditedVideo={((e) => {
                        setCurrentVideo((prevState) => ({
                            ...prevState,
                            b2b_video_link: e,
                        }));
                    })}

                /> */}

                {/* <ManualUploadModal
                    open={isManualModalOpen}
                    setOpen={setManualModelOpen}
                    currentVideo={currentVideo}
                    setCurrentVideo={setCurrentVideo}
                    submitVideo={((e)=>{
                        submitVideo(e)
                    })}

                /> */}



      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={((e)=>{
            setIsModalOpen(false);
        })}
        onConfirm={handleConfirm}
        onCancel={((e)=>{})}
        message="Are you sure you want to proceed?"
      />

            </Layout>

            {
                isLoading ?
                    <Loader />
                    : null
            }

        </div>

    );
}

export default Published;
