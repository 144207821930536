import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom'

import Login from "./pages/Login"
import Users from "./pages/Users"
import Videos from "./pages/Videos"
import Finance from "./pages/Finance"
import Preview from "./pages/Preview"
import Published from "./pages/Published"
import SocialPages from "./pages/SocialPages"
import Profile from "./pages/Profile"
import RecycleBin from "./pages/RecycleBin"
import B2BLogin from "./pages/B2B/B2BLogin"
import BusinessHome from "./pages/B2B/BusinessHome"
import BusinessStats from "./pages/B2B/BusinessStats"
import BusinessVideos from "./pages/B2B/BusinessVideos"
import AutomatedReels from "./pages/AutomatedReels"
import Library from "./pages/B2B/Library"


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} ></Route>
        <Route path="/login" element={<Login />} ></Route>
        <Route path="/users" element={<Users />} ></Route>
        <Route path="/videos" element={<Videos />} ></Route>
        <Route path="/finance" element={<Finance />} ></Route>
        <Route path="/preview" element={<Preview />} ></Route>
        <Route path="/published" element={<Published />} ></Route>
        <Route path="/social_pages" element={<SocialPages />} ></Route>
        <Route path="/profile" element={<Profile />} ></Route>
        <Route path="/recycle_bin" element={<RecycleBin />} ></Route>
        <Route path="/business" element={<B2BLogin />} ></Route>
        <Route path="/business_home" element={<BusinessHome />} ></Route>
        <Route path="/business_stats" element={<BusinessStats />} ></Route>
        <Route path="/library" element={<Library />} ></Route>
        
        <Route path="/b2b_videos" element={<BusinessVideos />} ></Route>
        <Route path="/automated_reels" element={<AutomatedReels />} ></Route>
        
        {/* <Route path="/upload" element={<UploadForm />} ></Route> */}
        {/* <Route path="/profile" element={<Profile />} ></Route> */}
        {/* <Route path="/wallet" element={<Wallet />} ></Route> */}
        {/* <Route path="/login" element={<Login />} ></Route> */}
        {/* <Route path="/terms" element={<Terms />} ></Route> */}
      </Routes>
      {/* <Header />
      <CTA/>
      <Trending/>
      <Footer/> */}
      {/* <UploadForm/> */}
      </div>

  );
}

export default App;
