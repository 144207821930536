import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/hint/javascript-hint';
import { Controlled as ControlledEditor } from 'react-codemirror2';
import React, { useState, useEffect, useRef } from "react";

export default function Editor(props) {
  const editor = useRef()
  const wrapper = useRef()
  
  const editorWillUnmount = () => {
    editor.current.display.wrapper.remove()
  }

    const { language, displayName, value, onChange } = props;

    const [open, setOpen] = useState(true);

    function handleChange(editor, data, value) {
        onChange(value);
    }

    return (
        <div className={`editor-container ${open ? '' : 'collapsed'}`}>
            <div className="editor-title">
                {displayName}
                <button
                    type='button'
                    className='expand-collapse-btn'
                    onClick={() => setOpen(prevOpen => !prevOpen)}>
                </button>
            </div>
            <ControlledEditor
                    editorDidMount={(e) => editor.current = e}
                    editorWillUnmount={editorWillUnmount}
            
                onBeforeChange={handleChange}
                value={value}
                className='code-mirror-wrapper'
                options={{
                    gutters: ["CodeMirror-lint-markers"],
                    mode: "javascript",
                    theme: "material",
                    lineNumbers: true,
                    lineWrapping: true,
                    lint: true,
          
                }}
            />
        </div>
    );
}