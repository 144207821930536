import Layout from "../components/Layout"
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import DeleteModal from "../components/DeleteModal"
import RecoverModal from "../components/RecoverModal"
import VideoPreview from "../components/Modals/VideoPreview"

// import axios from 'axios';
import * as moment from 'moment';
import axiosInstance from '../services/api'; // Adjust the path based on your file structure

function RecycleBin() {
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState(0)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isRecoverModalOpen, setRecoverModalOpan] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState({})
    const [rejection, setRejection] = useState("")
    const [videoSelected, setVideoSelected] = useState({})
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)


    const [videos, setVideos] = useState(
        [
            {
                _id: 1276856179641324,
                total_views: 234327,
                total_impressions: 388957,
                earning_cents: 1460.15377,
                channel: "Facebook"
            },
            {
                _id: 123295500852098,
                total_views: 431705,
                total_impressions: 586351,
                earning_cents: 1140.113697,
                channel: "Facebook"
            },

            {
                _id: 123295500852098,
                total_views: 431705,
                total_impressions: 586351,
                earning_cents: 1140.113697,
                channel: "Snapchat"
            },

            // {
            //     _id: 0,
            //     user: "Zaid Sallam",
            //     video: "video22.mp4",
            //     category: "Kids",
            //     date: "2023-10-01 22:00pm GMT+3"
            // }
        ]
    )


    useEffect(() => {
        getRejected()
    }, []);


    async function getRejected() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/rejected');
        setVideos(response.data)
    }


    async function deleteItem() {
        if (selectedVideo._id) {
            const response = await axiosInstance.put('https://api.telewee.com/videos/' + selectedVideo._id, {
                "status": "deleted",
                "rejection_reason": ""
            });
            alert("Video has been Removed Permenantly")
            getRejected()
        } else {
            alert("You should select a video!")
        }
    }

    async function recoverVideo() {

        if (selectedVideo._id) {
            const response = await axiosInstance.put('https://api.telewee.com/videos/' + selectedVideo._id, {
                "status": "pending",
                "rejection_reason": ""
            });
            alert("Video has been Recovered")
            getRejected()
        } else {
            alert("You should select a video!")
        }
    }
    


    return (
        <Layout>
            <div class="pl-4 pr-4 sm:ml-64">

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Rejected Published</h3>
                                        <p class="text-3xl font-bold text-black">{videos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Facebook Total Income</h3>
                                        <p class="text-3xl font-bold text-black">100 USD</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Snapchat Total Income</h3>
                                        <p class="text-3xl font-bold text-black">10k</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>









                <div class="relative overflow-x-auto">

                    <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>

                                <th scope="col" class="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Rejected Reason
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    User Name
                                </th>


                                <th scope="col" class="px-6 py-3">
                                    Deleted on
                                </th>

                                <th scope="col" class="px-6 py-3">
                                    Video
                                </th>

                                {/* <th scope="col" class="px-6 py-3">
                                    Open
                                </th> */}

                                {/* <th scope="col" class="px-6 py-3">
                                    Total Videos
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                videos.map((item) => (

                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                        <td class="px-6 py-4">
                                            <div class="flex items-center text-red-400">
                                                {item.status}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {/* {facebook_channel(item.earning_cents)/100} */}
                                                {item.rejection_reason}

                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {/* {facebook_channel(item.earning_cents)/100} */}
                                                {item.user ? item.user.full_name : ""}

                                            </div>
                                        </td>


                                        {/* <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.total_impressions}

                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <a class="flex items-center">
                                                {item.total_views}

                                            </a>
                                        </td> */}

<td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {moment(item.updated_on).format("YYYY-MM-DD hh:mm")}

                                            </div>
                                        </td>

                                        <td class="flex items-center px-6 py-4 space-x-3">
                                            <a
                                                // href={"https://www.facebook.com/3absiwhadeel/videos/" + item.facebook_video_id} 
                                                // href={item.video_link}
                                                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"

                                                onClick={((e) => {
                                                setSelectedVideo(item)

                                                setTimeout(() => {
                                                    setIsPreviewOpen(true)
                                                }, 300);

                                                })}>Preview Video </a>
                                            <a href="#" class="font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {


                                                setIsDeleteModalOpen(true)
                                                setSelectedVideo(item)
                                            })}>Permenant Delete</a>


                                            <a href="#" class="font-medium text-green-600 dark:text-green-350 hover:underline" onClick={((e) => {
                                                setSelectedVideo(item)
                                                setRecoverModalOpan(true)
                                            })}>Recover</a>
                                        </td>

                                   

                                    </tr>

                                )
                                )
                            }




                        </tbody>
                    </table>

                </div>



            </div>



            <VideoPreview

                isPreviewOpen={isPreviewOpen}
                setIsPreviewOpen={setIsPreviewOpen}
                video_link={selectedVideo.edited_video_link?selectedVideo.edited_video_link:selectedVideo.video_link}
            />


            <DeleteModal
                is_reject_modal={false}
                setRejectionReason={((e) => {
                    if (e != "")
                        setRejection(e)
                })}
                open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen} deleteFunc={deleteItem} />




<RecoverModal
                setRejectionReason={((e) => {
                    if (e != "")
                        setRejection(e)
                })}
                open={isRecoverModalOpen} setOpen={setRecoverModalOpan} recoverFunc={recoverVideo} />




                
        </Layout>
    );
}

export default RecycleBin;
