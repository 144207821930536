import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import * as moment from 'moment';

function Table({ videos, setOpenedB2bPublishModal, view, setVideoSelected, setRejectB2BModel,setIsPreviewOpen }) {
    const navigate = useNavigate()

    return (
        <div class="pl-4 pr-4 sm:ml-64">




            <div class="relative overflow-x-auto">


                {
                    view == 'pending' ?
                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {/* <th scope="col" class="px-6 py-3">
                User
            </th> */}
                                    <th scope="col" class="px-6 py-3">
                                        Video ID
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Video
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Description
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        category
                                    </th>

                                    {/* <th scope="col" class="px-6 py-3">
                Owned by User
            </th> */}
                                    <th scope="col" class="px-6 py-3">
                                    Assigning Date
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Action
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {

                                    videos.map((item) => (

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            {/* <td class="px-6 py-4">
                        <div class="flex items-center">
                            {item.user ? item.user.full_name : ""}
                        </div>
                    </td> */}
                                            <td class="  px-6 py-4">
                                                <div class="flex items-center ">
                                                    {item._id}
                                                </div>
                                            </td>
                                            <td class="px-8 py-4 w-[200px]">
                                                <div
                                                onClick={
                                                    ((e)=>{
                                                        setVideoSelected(item)
                                                        setTimeout(() => {
                                                        setIsPreviewOpen(true)
                                                        }, 150);
                                                    })
                                                }
                                                class="flex items-center underline text-blue-800">
                                                    {/* <a href={item.edited_video_link}> */}
                                                        Preview Video
                                                        {/* </a> */}
                                                </div>
                                            </td>
                                            <td class=" w-[300px] px-6 py-4">
                                                <div class="flex items-center ">
                                                    {item.description}
                                                </div>
                                            </td>

                                            <td class="  px-6 py-4">
                                                <div class="flex items-center ">
                                                    {item.category}
                                                </div>
                                            </td>

                                            {/* 
                    <td class="px-6 py-4">
                        <div class="flex items-center">
                            {item.is_owned ? "Yes" : "No"}

                        </div>
                    </td> */}
                                            <td class="px-10 py-4">
                                                <a class="flex items-center">
                                                    {(item.assigned_on)?item.assigned_on:"Last Month"
                                                    
                                                    }
                                                    

                                                </a>
                                            </td>
                                            <td class="flex items-center px-6 py-4 space-x-3">
                                                <div  class="font-medium text-green-600  hover:underline" onClick={((e) => {
                                                    setOpenedB2bPublishModal(true)
                                                    setVideoSelected(item)
                                                })}>Label as Published</div>

                                                <div  class="font-medium text-red-600  hover:underline" onClick={((e) => {
                                                    
                                                    setRejectB2BModel(true)
                                                    setVideoSelected(item)

                                                })}>Reject/Decline</div>
                                            </td>

                                        </tr>

                                    )
                                    )
                                }


                            </tbody>
                        </table> :




                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    {/* <th scope="col" class="px-6 py-3">
            User
        </th> */}
                                    <th scope="col" class="px-6 py-3">
                                        Video
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Description
                                    </th>
                                    {/* <th scope="col" class="px-6 py-3">
        category
        </th> */}

                                    <th scope="col" class="px-6 py-3">
                                        Facebook ID
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Total Earning
                                    </th>

                                    <th scope="col" class="px-6 py-3">
                                        Your Percentage
                                    </th>

                                    <th scope="col" class="px-6 py-3">
                                        Views
                                    </th>

                                    <th scope="col" class="px-6 py-3">
                                        Published Date
                                    </th>
                                    {/* <th scope="col" class="px-6 py-3">
                                        Action
                                    </th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {

                                    videos.map((item) => (

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            {/* <td class="px-6 py-4">
                    <div class="flex items-center">
                        {item.user ? item.user.full_name : ""}
                    </div>
                </td> */}
                                            <td class="px-6 py-4 w-[100px]">
                                                <div class="flex items-center underline text-blue-800">
                                                    <a 
                                                    
                                                    href={"https://www.facebook.com/telescopemediagroup/videos/" + item.facebook_b2b_video_id} 
                                                    // href={item.edited_video_link}
                                                    >Video</a>
                                                </div>
                                            </td>
                                            <td class=" w-[300px] px-6 py-4">
                                                <div class="flex items-center ">
                                                    {item.description}
                                                </div>
                                            </td>

                                            {/* <td class=" px-6 py-4">
                    <div class="flex items-center ">
                        {item.category}
                    </div>
                </td> */}


                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.facebook_b2b_video_id}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.b2b_total_earning}
                                                </div>
                                            </td>
                                         
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.b2b_vendor_earning ? item.b2b_vendor_earning : 0}
                                                </div>
                                            </td>


                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.b2b_fb_total_video_views}
                                                </div>
                                            </td>

                                            <td class="px-10 py-4">
                                                <a class="flex items-center">
                                                    {(item.b2b_published_on)?item.b2b_published_on:"Last Month"
                                                    //.format("YYYY-MM-DD hh:mm")
                                                    }

                                                </a>
                                            </td>
                                            {/* <td class="flex items-center px-6 py-4 space-x-3">
                    <a href="#" class="font-medium text-green-600  hover:underline" onClick={((e) => {
                        setOpenedB2bPublishModal(true)
                    })}>Label as Published</a>
                </td> */}

                                        </tr>

                                    )
                                    )
                                }


                            </tbody>
                        </table>
                }


            </div>


        </div>
    );
}
export default Table;
