import Layout from "../../components/Layout"
import { useNavigate, } from 'react-router-dom'
import { useState, useEffect, useCallback, useRef } from 'react'

import DeleteModal from "../../components/DeleteModal"
import EditPublishedVideoModal from "../../components/Modals/EditPublishedVideoModal"
import ManualUploadModal from "../../components/Modals/ManualUploadModal"

// import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as moment from "moment"
import axiosInstance from '../../services/api'; // Adjust the path based on your file structure

function Published() {
    const navigate = useNavigate()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isManualModalOpen, setManualModelOpen] = useState(false)
    const [isPickerOpened, setIsPickerOpened] = useState(false)
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [videosCount, setVideosCount] = useState(0);
    const [inputSearch, setInputSearch] = useState("");
    const [vendors, setVendors] = useState([])
    const [currentTab, setCurrentTab] = useState(0)
    // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const [rejection_reason, setRejectionReason] = useState("")
    // 65a7ff9ffa0b9186894d2b76

    const [videos, setVideos] = useState(
        []
    )

    const [currentVideo, setCurrentVideo] = useState({});
    const [b2bPendingVideos, setB2bPendingVideos] = useState([]) //
    const [b2bPublishedVideos, setB2bPublishedVideos] = useState([]) //
    const [b2bRejectedVideos, setB2bRejectedVideos] = useState([]) //
    const [b2bRevenue, setB2bRevenue] = useState({
        "total_earning": 0,
        "telewee_earning": 0,
        "user_earning": 0,
        "vendor_earning": 0
    }) //approved


    async function getB2BPendingVideos() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/b2b_pending');
        setB2bPendingVideos(response.data)
    }

    async function getB2BPublishedVideos() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/b2b_published');
        setB2bPublishedVideos(response.data.videos)
        setB2bRevenue(response.data.revenue)
    }

    async function getB2BRejectedVideos() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/b2b_rejected');
        setB2bRejectedVideos(response.data)
    }


    
    useEffect(() => {
        getB2BPendingVideos()
        getB2BPublishedVideos()
        getB2BRejectedVideos()
        getVendors()
    }, []);


    async function getVendors() {
        const response = await axiosInstance.get('https://api.telewee.com/vendors');
        setVendors(response.data)
    }





    async function deleteItem() {
        console.log(currentVideo)
        if (currentVideo._id) {
            const response = await axiosInstance.put('https://api.telewee.com/videos/' + currentVideo._id, {
                "status": "rejected",
                "rejection_reason": rejection_reason,
                "vendor":null
            });

            alert("Moved to recyclebin successfully!")
            getB2BRejectedVideos()
        } else {
            alert("You should select a video!")
        }
    }

    async function editItem(updatedVideo) {
        console.log("this video has been modified= " + updatedVideo._id)
        console.log(updatedVideo)

        if (updatedVideo._id) {
            const response = await axiosInstance.put('https://api.telewee.com/videos/' + updatedVideo._id, 
            {
                ...updatedVideo,
                "vendor_status": "pending",
                "vendor": updatedVideo.vendor?updatedVideo.vendor:null,
                "b2b_rejection_reason": null
            });
            alert("Video has been updated")
            getB2BRejectedVideos()

        } else {
            alert("You should select a video!")
        }
    }

    return (
        <Layout>
            <div class="pl-4 pr-4 sm:ml-64">

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-gray-100 rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-gray-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2B Assigned Videos</h3>
                                        <p class="text-3xl font-bold text-black">{b2bPendingVideos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" inline-block align-bottom bg-green-100 rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-green-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2B Published Videos</h3>
                                        <p class="text-3xl font-bold text-black">{b2bPublishedVideos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-red-100 rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-red-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2B Rejected Videos</h3>
                                        <p class="text-3xl font-bold text-black">{b2bRejectedVideos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Earning$</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(b2bRevenue.total_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Telewee Earning$</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(b2bRevenue.telewee_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Teleweelers Earnings$ </h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(b2bRevenue.user_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Clients Earnings$</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(b2bRevenue.vendor_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>



                <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200  ">
                    <ul class="flex flex-wrap -mb-px">

                                <li onClick={((e) => { setCurrentTab(0) })} class="mr-2">
                                    <a href="#" class={`${currentTab == 0 ? "border-blue-800" : ""}  inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}>Assigned</a>

                                </li>

                                <li onClick={((e) => { setCurrentTab(1) })} class="mr-2">
                                    <a href="#" class={`${currentTab == 1 ? "border-blue-800" : ""} inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`} >Published</a>
                                </li>

                                <li onClick={((e) => { setCurrentTab(2) })} class="mr-2">
                                    <a href="#" class={`${currentTab == 2 ? "border-blue-800" : ""} inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`} >Rejected</a>
                                </li>



                    </ul>
                </div>







                <div class="relative overflow-x-auto">



                    {
                        currentTab==0?
                        <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                            <th scope="col" class="px-6 py-3">
                                    Video Id
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Video
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Category
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Description
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Client
                                </th>
                                <th scope="col" class="px-6 py-3">
                             Actions
                         </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                b2bPendingVideos.map((item, index) => (
                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                             <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item._id}
                                                </div>
                                            </td>
                                            <td class="flex items-center px-6 py-4 space-x-3">
                                                <a href={item.edited_video_link} class="font-medium text-gray-600 dark:gray-blue-500 hover:underline" onClick={((e) => {

                                                })}>Video </a>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.category}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.description}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.vendor.company}

                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    <div
                                                     class="font-medium text-blue-600 dark:text-blue-350 hover:underline" onClick={((e) => {
                                                        setCurrentVideo(item)
                                                        setTimeout(() => {
                                                            setIsEditModalOpen(true)
                                                        }, 300);
                                                    })}>Edit</div>

                                                    <div class="ml-3 font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {
                                                        setIsDeleteModalOpen(true)
                                                        setCurrentVideo(item)
                                                    })}>Delete </div>
                                                </div>
                                            </td>
                                        </tr> 
                                )
                                )
                            }
                        </tbody>
                    </table>:
                    currentTab==1?
                    <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                        <th scope="col" class="px-6 py-3">
                                Video Id
                            </th>
                            <th scope="col" class="px-6 py-3">
                                FB Video Id
                            </th>
                            <th scope="col" class="px-6 py-3">
                                 Video
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Category
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Client
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total Earning
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Telewee Earning
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Teleweeler Earning
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Client Earning
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            b2bPublishedVideos.map((item, index) => (
                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                         <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item._id}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.facebook_b2b_video_id}
                                            </div>
                                        </td>
                                        <td class="flex items-center px-6 py-4 space-x-3">
                                            <a href={
                                            "https://www.facebook.com/telescopemediagroup/videos/" + item.facebook_b2b_video_id    
                                                // item.edited_video_link
                                            
                                            } class="font-medium text-blue-600 dark:gray-blue-500 underline" onClick={((e) => {


                                            })}>Video Link </a>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.category}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.description}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.vendor.company}
                                            </div>
                                        </td>

                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.total_earning}
                                            </div>
                                        </td>

                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.telewee_earning}
                                            </div>
                                        </td>

                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.user_earning}
                                            </div>
                                        </td>

                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.user_earning}
                                            </div>
                                        </td>
                                    </tr> 
                            )
                            )
                        }
                    </tbody>
                </table>:
                 <table class="w-full text-sm text-left text-gray-500">
                 <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                     <tr>
                     <th scope="col" class="px-6 py-3">
                             Video Id
                         </th>
                         <th scope="col" class="px-6 py-3">
                             Video
                         </th>
                         <th scope="col" class="px-6 py-3">
                             Category
                         </th>
                         <th scope="col" class="px-6 py-3">
                             Rejection Reason
                         </th>
                         <th scope="col" class="px-6 py-3">
                             Client
                         </th>
                         <th scope="col" class="px-6 py-3">
                             Actions
                         </th>
                     </tr>
                 </thead>
                 <tbody>
                     {
                         b2bRejectedVideos.map((item, index) => (
                                 <tr class="bg-white border-b  hover:bg-gray-50">
                                      <td class="px-6 py-4">
                                         <div class="flex items-center">
                                             {item._id}
                                         </div>
                                     </td>
                                     <td class="flex items-center px-6 py-4 space-x-3">
                                         <a href={item.edited_video_link} class="font-medium text-gray-600 dark:gray-blue-500 hover:underline" onClick={((e) => {

                                         })}>Video </a>
                                     </td>
                                     <td class="px-6 py-4">
                                         <div class="flex items-center">
                                             {item.category}
                                         </div>
                                     </td>
                                     <td class="px-6 py-4">
                                         <div class="flex items-center">
                                             {item.b2b_rejection_reason}
                                         </div>
                                     </td>
                                     <td class="px-6 py-4">
                                         <div class="flex items-center">
                                             {item.vendor.company}

                                         </div>
                                     </td>



                                     <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    <div
                                                     class="font-medium text-blue-600 dark:text-blue-350 hover:underline" onClick={((e) => {
                                                        setCurrentVideo(item)
                                                        setTimeout(() => {
                                                            setIsEditModalOpen(true)
                                                        }, 300);
                                                    })}>Edit</div>

                                                    <div class="ml-3 font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {
                                                        setIsDeleteModalOpen(true)
                                                        setCurrentVideo(item)
                                                    })}>Delete </div>
                                                </div>
                                            </td>

                                 </tr> 
                         )
                         )
                     }
                 </tbody>
             </table>
                    }
                   



                </div>
            

            </div>



            <DeleteModal
                is_reject_modal={false}
                setRejectionReason={setRejectionReason}
                open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
                 deleteFunc={deleteItem}
            />

            <EditPublishedVideoModal

                open={isEditModalOpen && currentVideo != {}}
                setOpen={setIsEditModalOpen}
                editFunc={editItem}
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                vendors={vendors}

                // setB2BEditedVideo={((e) => {
                //     setCurrentVideo((prevState) => ({
                //         ...prevState,
                //         b2b_video_link: e,
                //     }));
                // })}

            />



            {/* <DeleteModal
                is_reject_modal={false}
                setRejectionReason={setRejectionReason}
                open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen} deleteFunc={deleteItem}
            />

            <EditPublishedVideoModal

                open={isEditModalOpen && currentVideo != {}}
                setOpen={setIsEditModalOpen}
                editFunc={editItem}
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                vendors={vendors}

            />

            <ManualUploadModal
                open={isManualModalOpen}
                setOpen={setManualModelOpen}
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                submitVideo={submitVideo}

            /> */}

        </Layout>
    );
}

export default Published;
