
// import Header from "../components/Header/index"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios';



export default function Login() {
  const navigate = useNavigate()
  const [email_address, setEmail] = useState("");
  const [password, setPassword] = useState("");



  async function login(e) {
    e.preventDefault()
    if (email_address && password)
      try {
        // Make a POST request to your API endpoint
        const response = await axios.post('https://api.telewee.com/admin/login', {
          "email_address": email_address,
          "password": password
        });

        if (response.data) {
          localStorage.setItem("user",JSON.stringify(response.data))

          if(response.data.role!='finance'){
            navigate('/videos')
            window.location.reload()

          }else{
            navigate('/finance')
            window.location.reload()
          }
          

        } else {
          alert("Wrong Credentials")
        }

      } catch (error) {
        // Handle errors, such as network errors or server errors
        console.error('Error:', error);
        alert("Wrong Credentials")
      }
    else
      alert("Please fill all fields")


  }



  return (
    <>

      <div class="grid grid-cols-2 divide-x">
        <div className="opacity-80">
          <img
            className="h-screen"
            src="./dash-hd.png"
            alt="Your Company"
          />

        </div>
        <div>
          <div className="h-screen flex items-center justify-center">
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                  className="mx-auto h-10 w-auto "
                  src="./Final-logo-black.png"
                  alt="Your Company"
                />
                <h2 className="mt-6 text-center text-2xl font leading-9 tracking-tight text-gray-900">
                  Admin Portal
                </h2>
              </div>

              <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={((e) => { setEmail(e.target.value) })}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center justify-between">
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                    </div>
                    <div className="mt-2">
                      <input
                        id="password"
                        onChange={((e) => { setPassword(e.target.value) })}

                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      onClick={((e) => { login(e) })}
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign in
                    </button>
                  </div>
                </form>


              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}
