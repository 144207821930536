// Loader.js
import React from "react";
import "./loader.css";

function Loader({percentage=null}) {
  return (
    <div className="loader-overlay ">
      <div className="loader">
      </div>
      {
        percentage?
        <div className="mx-2" >{percentage}</div>
        :null
      }


    </div>
  );
}

export default Loader;
