import Layout from "../components/Layout"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import DeleteModal from "../components/DeleteModal"

function Published() {
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState(0)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [selectedVideoId, setSelectedVideoId] = useState(null)

    const [pages, setPages] = useState(
        [
            {
                _id: 1,
                page_name: "Telescope",
                channel: "Facebook"
            },
            {
                _id: 2,
                page_name: "Absi",
                channel: "Facebook"
            },
            {
                _id: 3,
                page_name: "اطفال السعودية",
                channel: "Facebook"
            },
            {
                _id: 4,
                page_name: "حيوانات الامازون",
                channel: "Facebook"
            },
            {
                _id: 5,
                page_name: "تكنولوجيا الخليج",
                channel: "Facebook"
            },
            // {
            //     _id: 0,
            //     user: "Zaid Sallam",
            //     video: "video22.mp4",
            //     category: "Kids",
            //     date: "2023-10-01 22:00pm GMT+3"
            // }
        ]
    )


    // function deleteItem() {
    //     alert("this video has been deleted= " + selectedVideoId)
    // }
    // function editItem() {
    //     alert("this video has been modified= " + selectedVideoId)
    // }


    return (
        <Layout>
            <div class="pl-4 pr-4 sm:ml-64">



{/* 
                <div className="mt-4 mb-4">
                    <button
                        onClick={((e) => { navigate('/users') })}
                        type="submit"
                        className="flex   rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm auto-ml"
                    >
                        Add Page +
                    </button>
                </div>
 */}




                <div class="relative overflow-x-auto">

                    <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Channel
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Page Name
                                </th>
                                {/* <th scope="col" class="px-6 py-3">
                                    Total Impressions
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Total Views
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Open
                                </th> */}

                                {/* <th scope="col" class="px-6 py-3">
                                    Total Videos
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            Pages will be listed soon
                            {/* {
                                pages.map((item) => (

                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.channel}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.page_name}

                                            </div>
                                        </td>

                                    </tr>

                                )
                                )
                            } */}




                        </tbody>
                    </table>

                </div>


            </div>


        </Layout>
    );
}

export default Published;
