// src/components/Modal.js
import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, onCancel, message }) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto">
        <div className="text-center mb-4">
          <p>{message}</p>
        </div>
        <div className="flex justify-around">
          <button
            onClick={handleConfirm}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
          >
            Accept
          </button>
          <button
            onClick={handleCancel}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
