import BusinessLayout from "../../components/BusinessLayout"
import Table from "../../components/Table"

import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import DeleteModal from "../../components/DeleteModal"
// import axios from 'axios';
import * as moment from 'moment';
import axiosInstance from '../../services/api'; // Adjust the path based on your file structure

function BusinessHome() {
    const navigate = useNavigate()
    // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    // const [selectedVideo, setSelectedVideo] = useState({})

    // const [pendingVideos, setPendingSetVideos] = useState([]) //pending
    // const [approvedVideos, setApprovedVideos] = useState([]) //approved
    // const [editingVideos, setEditingVideos] = useState([]) //editing


    // const [facebookVideoId, setFacebookVideoId] = useState("")
    // const [facebookPageName, setFacebookPageName] = useState("")
    // const [videoCategory, setVideoCategory] = useState("")

    // const user = JSON.parse(localStorage.getItem("user"))
    // const [currentTab, setCurrentTab] = useState(user.role == 'qc' ? 1 : 0)
    // const [editedVideo, setEditedVideo] = useState("")
    // const [rejection, setRejection] = useState("")

    const [videos, setVideos] = useState([]) 
    const [revenue, setRevenue] = useState({
         "total_earning": 0,
    "telewee_earning": 0,
    "user_earning": 0,
    "vendor_earning": 0
}) 
    // const [openedB2bPublishModal, setOpenedB2bPublishModal] = useState(false)
    // const [facebookVideoId, setFacebookVideoId] = useState("")
    
    // const [approvedVideos, setApprovedVideos] = useState([]) //approved
    // const [editingVideos, setEditingVideos] = useState([]) //editing


    // const [facebookVideoId, setFacebookVideoId] = useState("")
    // const [facebookPageName, setFacebookPageName] = useState("")
    // const [videoCategory, setVideoCategory] = useState("")

    const user = JSON.parse(localStorage.getItem("user"))



    // useEffect(() => {
    //     getPendingVideos()
    //     getApprovedVideos()
    //     getEditingVideos()
    // }, []);



    useEffect(() => {
        getPublishedVideos()
    }, []);


    async function getPublishedVideos() {
        if (user['_id']) {
            const response = await axiosInstance.get(`https://api.telewee.com/vendors/${user['_id']}/published_videos`);
            setVideos(response.data.videos)
            setRevenue(response.data.revenue)
        }
    }



    return (
        <BusinessLayout>
            <div class="pl-4 pr-4 sm:ml-64">
                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Published Videos</h3>
                                        <p class="text-3xl font-bold text-black">{videos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Revenue</h3>
                                        <p class="text-3xl font-bold text-black">{revenue.vendor_earning}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="relative overflow-x-auto">
                <Table
                videos={
                   videos
                } 
                view="stats"
                />
            </div>

        </BusinessLayout>
    );
}

export default BusinessHome;
