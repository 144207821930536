import BusinessLayout from "../../components/BusinessLayout"
import Table from "../../components/Table"

import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import DeleteModal from "../../components/DeleteModal"
import B2bPublishModal from "../../components/Modals/B2bPublishModal"
import B2bRejectModal from "../../components/Modals/B2bRejectModal"
import VideoPreview from "../../components/Modals/VideoPreview"
// import axios from 'axios';
import * as moment from 'moment';
import axiosInstance from '../../services/api'; // Adjust the path based on your file structure

function BusinessHome() {
    const navigate = useNavigate()
    // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    // const [selectedVideo, setSelectedVideo] = useState({})

    const [videos, setVideos] = useState([])
    const [openedB2bPublishModal, setOpenedB2bPublishModal] = useState(false)
    const [rejectB2BModal, setRejectB2BModel] = useState(false)

    const [facebookVideoId, setFacebookVideoId] = useState("")
    const [videoSelected, setVideoSelected] = useState({})
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    // const [approvedVideos, setApprovedVideos] = useState([]) //approved
    // const [editingVideos, setEditingVideos] = useState([]) //editing


    // const [facebookVideoId, setFacebookVideoId] = useState("")
    // const [facebookPageName, setFacebookPageName] = useState("")
    // const [videoCategory, setVideoCategory] = useState("")

    const user = JSON.parse(localStorage.getItem("user"))
    // const [currentTab, setCurrentTab] = useState(user.role == 'qc' ? 1 : 0)
    // const [editedVideo, setEditedVideo] = useState("")
    const [rejectionReason, setRejectionReason] = useState("")




    useEffect(() => {
        getAssignedVideos()
    }, []);


    async function getAssignedVideos() {
        if (user['_id']) {
            const response = await axiosInstance.get(`https://api.telewee.com/vendors/${user['_id']}/assigned_videos`);
            setVideos(response.data)
        }
    }




    async function rejectB2B() {
        if (videoSelected._id) {
            try {
                const response = await axiosInstance.put('https://api.telewee.com/videos/' + videoSelected._id, {
                    "vendor_status": "rejected",
                    "b2b_rejection_reason": rejectionReason
                });
                getAssignedVideos()
            } catch (err) {
                alert(err.message)
            }
        }
    }

    async function submitFunc() {
        if (videoSelected._id && facebookVideoId) {
            try {
                const response = await axiosInstance.put('https://api.telewee.com/videos/' + videoSelected._id, {
                    "facebook_b2b_video_id": facebookVideoId,
                    "vendor_status": "published"
                });

                getAssignedVideos()
            } catch (err) {
                alert(err.message)
            }
        }
    }






    return (
        <BusinessLayout>
            <div class="pl-4 pr-4 sm:ml-64">

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Assigned Videos</h3>
                                        <p class="text-3xl font-bold text-black">{videos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>





            <div class="relative overflow-x-auto">

                <Table videos={
                    videos
                }
                    setOpenedB2bPublishModal={setOpenedB2bPublishModal}
                    setRejectB2BModel={setRejectB2BModel}
                    setVideoSelected={setVideoSelected}
                setIsPreviewOpen={setIsPreviewOpen}

                    view="pending"

                />

            </div>



            <VideoPreview

                isPreviewOpen={isPreviewOpen}
                setIsPreviewOpen={setIsPreviewOpen}
                video_link={videoSelected.b2b_video_link?videoSelected.b2b_video_link:videoSelected.edited_video_link}
            />



            <B2bPublishModal
                openedB2bPublishModal={openedB2bPublishModal}
                setOpenedB2bPublishModal={setOpenedB2bPublishModal}
                setFacebookVideoId={setFacebookVideoId}
                submitFunc={submitFunc}
            />


            <B2bRejectModal
                rejectB2BModal={rejectB2BModal}
                setRejectB2BModel={setRejectB2BModel}
                rejectB2BFunc={rejectB2B}
                setRejectionReason={setRejectionReason}
            />


        </BusinessLayout>
    );
}

export default BusinessHome;
