import Layout from "../components/Layout"
import { useNavigate, } from 'react-router-dom'
import { useState, useEffect, useCallback, useRef } from 'react'

import DeleteModal from "../components/DeleteModal"
import EditPublishedVideoModal from "../components/Modals/EditPublishedVideoModal"
import ManualUploadModal from "../components/Modals/ManualUploadModal"

// import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as moment from "moment"
import axiosInstance from '../services/api'; // Adjust the path based on your file structure
import Loader from "../components/Loader/index"

function Published() {
    const navigate = useNavigate()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isManualModalOpen, setManualModelOpen] = useState(false)
    const [isPickerOpened, setIsPickerOpened] = useState(false)
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [videosCount, setVideosCount] = useState(0);
    const [inputSearch, setInputSearch] = useState("");
    const [vendors, setVendors] = useState([])
    
    const [isLoading, setIsLoading] = useState(false);

    const [rejection_reason, setRejectionReason] = useState("")
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })




    const [videos, setVideos] = useState(
        [

        ]
    )

    const [currentVideo, setCurrentVideo] = useState({});



   
  
    // Scroll to bottom on initial render or when data changes
    // useEffect(() => {
    //   scrollToBottom();
    // }, [videos]);



    useEffect(() => {
        getPublishedVideos();
        getVendors()

    }, []);



    async function getVendors() {
        const response = await axiosInstance.get('https://api.telewee.com/vendors');
        setVendors(response.data)
    }


    


    async function getPublishedVideos(facebook_video_id = null) {
        try {
            let nextPage = page; // Initialize nextPage with the current page value
            let response;

            setIsLoading(true)

            if (!facebook_video_id) {
                response = await axiosInstance.get(`https://api.telewee.com/videos/published?page=${page}`);
                setVideos((prevRecords) => [...prevRecords, ...response.data['videos']]);
                nextPage += 1; // Increment nextPage only when there's more data to load
                setPage(nextPage); // Update the page state
                setIsLoading(false)

            } else {
                response = await axiosInstance.get(`https://api.telewee.com/videos/published?facebook_video_id=${facebook_video_id}`);
                setVideos(response.data['videos']);
            setIsLoading(false)


            }

            setVideosCount(response.data['count']);

      

       

        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }




    async function deleteItem() {
        if (currentVideo._id) {

            const response = await axiosInstance.put('https://api.telewee.com/videos/' + currentVideo._id, {
                "status": "rejected",
                "rejection_reason": rejection_reason
            });

            alert("Moved to recyclebin successfully!")
            getPublishedVideos()
        } else {
            alert("You should select a video!")
        }
    }

    async function editItem(updatedVideo) {
        console.log("this video has been modified= " + updatedVideo._id)
        console.log(updatedVideo)

        if (updatedVideo._id) {
            const response = await axiosInstance.put('https://api.telewee.com/videos/' + updatedVideo._id, updatedVideo);
            alert("Video has been updated")
            getPublishedVideos()
        } else {
            alert("You should select a video!")
        }
    }




    async function submitVideo() {



        try {
            if (currentVideo.video_link && currentVideo.user && currentVideo.facebook_channel && currentVideo.facebook_video_id) {
                const response = await axiosInstance.post('https://api.telewee.com/videos/manual', currentVideo);
                alert("Video has been published")
                setManualModelOpen(false)
                
                setTimeout(() => {
                    window.location.reload()
                }, 200);

            } else {
                alert("You should select a video!")
            }
        }
        catch (err) {
            alert("Telweeler ID is wrong or something went wrong")
        }
    }


    function handleSelect(ranges) {

        setSelectionRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
        })

        setTimeout(async () => {
            const response = await axiosInstance.get(`https://api.telewee.com/videos/published?page=${0}&from=${moment(ranges.selection.startDate).format("YYYY-MM-DD")
                }&to=${moment(ranges.selection.endDate).format("YYYY-MM-DD")
                }`);
            setVideos(response.data['videos']);
            setVideosCount(response.data['count']);
        }, 2000);

    }



    return (
        <div>
  <Layout>
            <div class="pl-4 pr-4 sm:ml-64">

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Videos Published</h3>
                                        <p class="text-3xl font-bold text-black">{videosCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Facebook Total Income</h3>
                                        <p class="text-3xl font-bold text-black">100 USD</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Snapchat Total Income</h3>
                                        <p class="text-3xl font-bold text-black">10k</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                            setManualModelOpen(true)

                            // setOpen(false)
                            // deleteFunc()
                        }}
                    >
                        Add new+
                    </button>




                </div>


                <button
                    type="button"
                    className="m-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white  focus:outline-none focus:ring-2  sm:ml-3 sm:w-auto sm:text-sm text-left"
                    onClick={() => {
                        setIsPickerOpened(!isPickerOpened)
                    }}
                >
                    Date Filter
                </button>

                {
                    isPickerOpened ?
                        <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            showMonthArrow={true}
                        />
                        : null
                }
                <input onChange={((e) => {
                    setInputSearch(e.target.value)

                    setTimeout(() => {

                        setVideos([])

                        if (e.target.value == "")
                            window.location.reload()

                    }, 100);

                    setTimeout(() => {
                        getPublishedVideos(e.target.value)
                    }, 200);

                })} type="text" name="text" id="text" placeholder="ID / FB-ID" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg    p-2.5 " required />






                <div class="relative overflow-x-auto">

                    <table  class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Video Id
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Facebook Video ID
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Channel
                                </th>
                                   <th scope="col" class="px-6 py-3">
                                    Category
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    User Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Views
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Unique Views
                                </th>

                                <th scope="col" class="px-6 py-3">
                                    Total Earnings
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Telewee Earnings
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    User Earnings
                                </th>










                                <th scope="col" class="px-6 py-3">
                                    B2B Total Earnings
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    B2B Telewee Earnings
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    B2B Telweeler Earnings
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    B2B Vendor Earnings
                                </th>





                                <th scope="col" class="px-6 py-3">
                                    Published On
                                </th>

                                <th scope="col" class="px-6 py-3">
                                    Video
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Actions
                                </th>
                                {/* <th scope="col" class="px-6 py-3">
                                    Open
                                </th> */}

                                {/* <th scope="col" class="px-6 py-3">
                                    Total Videos
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                videos.map((item, index) => (
                                    item.facebook_video_id && item.facebook_video_id != "" ?

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item._id}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.facebook_video_id}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.facebook_channel}

                                                </div>
                                            </td>
                                               <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.category?item.category:"N/A"}

                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.user ? item.user.full_name : ""}

                                                </div>
                                            </td>

                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.fb_total_video_views}
                                                </div>
                                            </td>

                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.fb_total_video_views_unique}
                                                </div>
                                            </td>



                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.total_earning}$
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.telewee_earning}$
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.user_earning}$
                                                </div>
                                            </td>




                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.b2b_total_earning}$
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.b2b_telewee_earning}$
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.b2b_user_earning}$
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {/* {facebook_channel(item.earning_cents)/100} */}
                                                    {item.b2b_vendor_earning}$
                                                </div>
                                            </td>












                                            <td class="px-6 py-4">
                                                <div class="flex items-center">

                                                    {item.updated_on ?
                                                        moment(item.updated_on).format("YYYY-MM-DD hh:mm") :
                                                        moment(item.created_on).format("YYYY-MM-DD hh:mm")

                                                    }
                                                </div>
                                            </td>

                                            {/* <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.total_impressions}

                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <a class="flex items-center">
                                                {item.total_views}

                                            </a>
                                        </td> */}
                                          

                                            <td className="flex items-center px-6 py-4 space-x-3">
  <a 
    href={`https://www.facebook.com/3absiwhadeel/videos/${item.facebook_video_id}`} 
    className="font-medium text-gray-600 dark:text-blue-500 hover:underline" 
    target="_blank" 
    rel="noopener noreferrer"
    onClick={(e) => {
      // Additional actions if needed
    }}>
    Video
  </a>
</td>

                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    <div
                                                     class="font-medium text-blue-600 dark:text-blue-350 hover:underline" onClick={((e) => {
                                                        setCurrentVideo(item)
                                                        setTimeout(() => {
                                                            setIsEditModalOpen(true)
                                                        }, 300);
                                                    })}>Edit</div>

                                                    <div class="ml-3 font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {
                                                        setIsDeleteModalOpen(true)
                                                        setCurrentVideo(item)
                                                    })}>Delete </div>
                                                </div>
                                            </td>



                                        </tr> : null

                                )
                                )
                            }




                        </tbody>
                    </table>



                </div>
                {/* {
                inputSearch==""?
                <div className="center text-center m-10 p-4 bg-gray-100 rounded">Loading more content...</div>
                    :null
                } */}

                {
                    videos.length < 10 ? null :
                    //  !isLoading?
                    <div
                            onClick={((e) => { getPublishedVideos() })}
                            className="center text-center m-10 p-4 bg-indigo-100 rounded"> <a>Load More</a> </div>

                // :null
                
                }


            </div>


            <DeleteModal
                is_reject_modal={false}
                setRejectionReason={setRejectionReason}
                open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen} deleteFunc={deleteItem}
            />

            <EditPublishedVideoModal

                open={isEditModalOpen && currentVideo != {}}
                setOpen={setIsEditModalOpen}
                editFunc={editItem}
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                vendors={vendors}

                setB2BEditedVideo={((e) => {
                    setCurrentVideo((prevState) => ({
                        ...prevState,
                        b2b_video_link: e,
                    }));
                })}

            />

            <ManualUploadModal
                open={isManualModalOpen}
                setOpen={setManualModelOpen}
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                submitVideo={submitVideo}
            />

        </Layout>
        
        {
            isLoading?
<Loader/>
:null
        }

        </div>
      
    );
}

export default Published;
