import axios from 'axios';

const user = JSON.parse(localStorage.getItem("user")) // account logged in / admin
const API_KEY = user?user.API_KEY?user.API_KEY:"":"" // You can also load this from environment variables
const axiosInstance = axios.create({
    baseURL: 'https://api.telewee.com', // Set your API base URL here
    headers: {
        'x-api-key': API_KEY,
    }
});

export default axiosInstance;
