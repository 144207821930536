import Layout from "../components/Layout"
import { useNavigate } from 'react-router-dom'
import WalletModal from "../components/WalletModal"
// import axios from 'axios';
import { useState, useEffect } from 'react'
import axiosInstance from '../services/api'; // Adjust the path based on your file structure

function Users() {
    const navigate = useNavigate()

    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)

    const [data, setData] = useState({
        "teleweelers_result": [],
        "pages_result": [],
        "total_earning": 0,
        "telewee_earning": 0,
        "user_earning": 0,
        "b2b_revenue": {
            "total_earning": 0,
            "telewee_earning": 0,
            "user_earning": 0,
            "vendor_earning": 0,
            "vendors": []
        }
    })




    async function getStats() {
        const response = await axiosInstance.get('https://api.telewee.com/finance');
        setData(response.data)
    }



    useEffect(() => {
        getStats()
    }, []);



    return (
        <Layout>
            <div class="pl-4 pr-4 sm:ml-64">

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">


                    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-indigo-400 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left text-white">
                                        <h3 class="text-sm leading-6 font-medium text-white">B2B + B2C Total</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(parseFloat(data.total_earning).toFixed(3))+ parseFloat(parseFloat(data.b2b_revenue.total_earning).toFixed(3))}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-green-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2C Total</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.total_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-green-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2C Telewee</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.telewee_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-green-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2C Teleweelers</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.user_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-indigo-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total B2B</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.b2b_revenue.total_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-indigo-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2B Telewee</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.b2b_revenue.telewee_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-indigo-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2B Teleweelers</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.b2b_revenue.user_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="inline-block align-bottom bg-indigo-100 rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-indigo-100 p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">B2B Vendors</h3>
                                        <p class="text-3xl font-bold text-black">{parseFloat(data.b2b_revenue.vendor_earning).toFixed(3)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>




                <div class="relative overflow-x-auto ">

                    <div class="flex ">
                        <div class="flex-1 mb-2 p-4 bg-gray-50">
                            <h1 class="text-lg  font-large ">Teleweelers Revenue</h1>

                            <div class="relative overflow-x-auto">

                                <table class="w-full text-sm text-left text-gray-500">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                Teleweeler
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Total Monetization
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Telwee Profit
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                User Profit
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.teleweelers_result.map((item) => (

                                                <tr class="bg-white border-b  hover:bg-gray-50">
                                                    <td class="px-6 py-4">
                                                        <div class="flex items-center">
                                                            {item._id}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4">
                                                        <div class="flex items-center">
                                                            {item.total_earning}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4">
                                                        <div class="flex items-center">
                                                            {item.telewee_earning}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4">
                                                        <a class="flex items-center">
                                                            {item.user_earning}
                                                        </a>
                                                    </td>

                                                </tr>
                                            )
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div class="flex-1 mb-2 p-4 bg-indigo-50">
                            <h1 class="text-lg  font-large ">Pages Revenue</h1>

                            <div class="relative overflow-x-auto">

                                <table class="w-full text-sm text-left text-gray-500">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                Page Name
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Total Monetization
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Telwee Profit
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                User Profit
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.pages_result.map((item) => (

                                                <tr class="bg-white border-b  hover:bg-gray-50">
                                                    <td class="px-6 py-4">
                                                        <div class="flex items-center">
                                                            {item._id}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4">
                                                        <div class="flex items-center">
                                                            {item.total_earning}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4">
                                                        <div class="flex items-center">
                                                            {item.telewee_earning}
                                                        </div>
                                                    </td>
                                                    <td class="px-6 py-4">
                                                        <a class="flex items-center">
                                                            {item.user_earning}
                                                        </a>
                                                    </td>

                                                </tr>
                                            )
                                            )
                                        }
                                    </tbody>
                                </table>

                            </div>


                            <div>


                                <h1 class="text-lg  font-large mt-2 ">B2B Vendors</h1>

                                <div class="relative overflow-x-auto">

                                    <table class="w-full text-sm text-left text-gray-500">
                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-6 py-3">
                                                    Vendor
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                    Total Monetization
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                    Telewee Profit
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                    Teleweelers Profit
                                                </th>
                                                <th scope="col" class="px-6 py-3">
                                                    Vendor Profit
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.b2b_revenue.vendors.map((item) => (

                                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                                        <td class="px-6 py-4">
                                                            <div class="flex items-center">
                                                                {item._id[0]}
                                                            </div>
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            <div class="flex items-center">
                                                                {item.b2b_total_earning}
                                                            </div>
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            <div class="flex items-center">
                                                                {item.b2b_telewee_earning}
                                                            </div>
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            <a class="flex items-center">
                                                                {item.b2b_user_earning}
                                                            </a>
                                                        </td>
                                                        <td class="px-6 py-4">
                                                            <a class="flex items-center">
                                                                {item.b2b_vendor_earning}
                                                            </a>
                                                        </td>

                                                    </tr>
                                                )
                                                )
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>



                    </div>
                </div>





            </div>

            <WalletModal open={isWalletModalOpen} setOpen={setIsWalletModalOpen} />


        </Layout>
    );
}

export default Users;
