import Layout from "../components/Layout"
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
// import axios from 'axios';
import DeleteUserModal from "../components/DeleteUserModal"
import axiosInstance from '../services/api'; // Adjust the path based on your file structure

function Users() {
    const navigate = useNavigate()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState({})
    const user = JSON.parse(localStorage.getItem("user")) // account logged in / admin
    const [users, setUsers] = useState([])

    useEffect(() => {
        getUsers()
    }, []);


    async function getUsers() {
        const response = await axiosInstance.get('https://api.telewee.com/users');
        console.log(response.data)
        setUsers(response.data)
    }


    async function deleteItem() {
        if (selectedUser._id) {
            const response = await axiosInstance.delete('https://api.telewee.com/users/' + selectedUser._id);
            alert("User has been deleted")
            getUsers()
            setIsDeleteModalOpen(false)
            setSelectedUser("")
        } else {
            alert("You should select a user!")
        }

    }
    return (
        <>
            <DeleteUserModal
                is_reject_modal={true}
                open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen} deleteFunc={deleteItem}
            />
            <Layout>
                <div class="pl-4 pr-4 sm:ml-64">

                    <div class="max-w-full mx-4 py-6 sm:mx-auto">
                        <div class="sm:flex sm:space-x-4">
                            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                                <div class="bg-white p-5">
                                    <div class="sm:flex sm:items-start">
                                        <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                            <h3 class="text-sm leading-6 font-medium text-gray-600">Total Users</h3>
                                            <p class="text-3xl font-bold text-black">{users.length}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>









                    <div class="relative overflow-x-auto">

                        <table class="w-full text-sm text-left text-gray-500">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        Full Name
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Email
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Phone
                                    </th>
                                    {/* <th scope="col" class="px-6 py-3">
                                    Attatchments (ID/Passport)
                                </th> */}



                                    <th scope="col" class="px-6 py-3">
                                        Actions
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((item) => (

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.full_name}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.email_address}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.phone_number}
                                                </div>
                                            </td>
                                            {/* <td class="px-6 py-4">
                                            <a class="flex items-center underline text-blue-600">
                                                {item.older_18?"Yes":"No"}
                                            </a>
                                        </td> */}

                                            <td class="px-6 py-4 flex" >
                                                {/* <a 
                                                onClick={((e) => {
                                                    navigate("/profile", { state: { user_id: item._id } })
                                                })}
                                                 class="flex items-center underline text-blue-600">
                                                    Open Profile
                                                </a> */}
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default anchor behavior
                                                        const newWindow = window.open(`/profile?user_id=${item._id}`, '_blank', 'noopener,noreferrer');
                                                        if (newWindow) newWindow.opener = null; // For security reasons
                                                    }}
                                                    className="flex items-center underline text-blue-600">
                                                    Open Profile
                                                </a>



                                                {
                                                    user.role == "admin" && user.email_address.toString().includes("super") ?
                                                        <a href="#" class="ml-2 font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {

                                                            setIsDeleteModalOpen(true)
                                                            setSelectedUser(item)

                                                        })}>Delete User</a>
                                                        :
                                                        null}

                                            </td>




                                        </tr>

                                    )
                                    )
                                }




                            </tbody>
                        </table>

                    </div>


                </div>

            </Layout>
        </>

    );
}

export default Users;
