import Layout from "../components/Layout"
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import DeleteModal from "../components/DeleteModal"
import EditVideoModal from "../components/EditVideoModal"
// import axios from 'axios';
import axiosInstance from '../services/api'; // Adjust the path based on your file structure

import * as moment from 'moment';

function Videos() {
    const navigate = useNavigate()
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState({})

    const [pendingVideos, setPendingSetVideos] = useState([]) //pending
    const [approvedVideos, setApprovedVideos] = useState([]) //approved
    const [editingVideos, setEditingVideos] = useState([]) //editing


    const [facebookVideoId, setFacebookVideoId] = useState("")
    const [facebookPageName, setFacebookPageName] = useState("")
    const [videoCategory, setVideoCategory] = useState("")

    const user = JSON.parse(localStorage.getItem("user"))
    const [currentTab, setCurrentTab] = useState(user.role == 'qc' ? 1 : 0)
    const [editedVideo, setEditedVideo] = useState("")
    const [rejection, setRejection] = useState("")
    const [vendors, setVendors] = useState([])
    const [selectedVendor, setSelectedVendor] = useState(null)




    useEffect(() => {
        getPendingVideos()
        getApprovedVideos()
        getEditingVideos()
        getVendors()
    }, []);


    async function getVendors() {
        const response = await axiosInstance.get('https://api.telewee.com/vendors');
        setVendors(response.data)
    }

    async function getPendingVideos() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/pending');
        setPendingSetVideos(response.data)
    }

    async function getApprovedVideos() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/approved');
        setApprovedVideos(response.data)
    }
    async function getEditingVideos() {
        const response = await axiosInstance.get('https://api.telewee.com/videos/editing');
        setEditingVideos(response.data)
    }




    async function deleteItem() {
        if (selectedVideo._id && rejection != "") {

            const response = await axiosInstance.put('https://api.telewee.com/videos/' + selectedVideo._id, {
                "status": "rejected",
                "rejection_reason": rejection
            });

            alert("Video has been rejected")
            getPendingVideos()
            getApprovedVideos()
            setRejection("")
        } else {
            alert("You should add a reason!")
        }

    }



    async function submitNote(note) {
        if (user._id && note != "") {
            await axiosInstance.post('https://api.telewee.com/videos/' + selectedVideo._id + "/add_note",
                {
                    "by_admin": user._id,
                    "note": note
                });
            alert("Note added sucessfully!")
        } else {
            alert("missing data or error!")

        }

    }


    async function editItem() {
        let new_status =
            selectedVideo.status == "pending" ?
                "approved" :
                selectedVideo.status == "approved" ?
                    "editing" :
                    selectedVideo.status == "editing" ?
                        "published" : null
        let body = {
            "status": new_status,
            
            "edited_video_link": selectedVideo.edited_video_link,
            "b2b_video_link": selectedVideo.b2b_video_link,
            "video_link": selectedVideo.video_link,
            
            "category": videoCategory,
            "vendor": (selectedVendor == null || selectedVendor == "") ? null : selectedVendor,
            "vendor_status": "pending"
        }

        if (new_status == "published") {
            body['facebook_video_id'] = facebookVideoId
            body['facebook_channel'] = facebookPageName
        }

        console.log(body)
        console.log(selectedVideo._id)

        try {
            const response = await axiosInstance.put('https://api.telewee.com/videos/' + selectedVideo._id, body);
            alert("Video has been updated!")
            getPendingVideos()
            getApprovedVideos()
            getEditingVideos()

        }
        catch (err) {
            alert("something went wrong" + err.message)
        }
    }


    return (
        <Layout>
            <div class="pl-4 pr-4 sm:ml-64">

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Pending Videos</h3>
                                        <p class="text-3xl font-bold text-black">{pendingVideos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Videos Under Editing</h3>
                                        <p class="text-3xl font-bold text-black">{approvedVideos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-600">Total Videos Under Publisher</h3>
                                        <p class="text-3xl font-bold text-black">{editingVideos.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200  ">
                    <ul class="flex flex-wrap -mb-px">

                        {
                            user.role == "admin" || user.role == "editor" ?
                                <li onClick={((e) => { setCurrentTab(0) })} class="mr-2">
                                    <a href="#" class={`${currentTab == 0 ? "border-blue-800" : ""}  inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}>Pending</a>

                                </li>
                                : null
                        }



                        {
                            user.role == "admin" || user.role == "qc" ?
                                <li onClick={((e) => { setCurrentTab(1) })} class="mr-2">
                                    {/*  */}
                                    <a href="#" class={`${currentTab == 1 ? "border-blue-800" : ""} inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`} >Editor</a>
                                </li>
                                : null
                        }


                        {
                            user.role == "admin" || user.role == "editor" ?
                                <li onClick={((e) => { setCurrentTab(2) })} class="mr-2">
                                    {/*  */}
                                    <a href="#" class={`${currentTab == 1 ? "border-blue-800" : ""} inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`} >Publisher</a>
                                </li>
                                : null
                        }


                    </ul>
                </div>





                <div class="relative overflow-x-auto">

                    <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    User
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Video
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Description
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Owned by User
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {

                                currentTab == 0 ?
                                    pendingVideos.map((item) => (

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.user ? item.user.full_name : ""}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 w-[100px]">
                                                <div class="flex items-center underline text-blue-800">
                                                    {item.video_link}
                                                </div>
                                            </td>
                                            <td class=" w-[300px] px-6 py-4">
                                                <div class="flex items-center ">
                                                    {item.description}
                                                </div>
                                            </td>

                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.is_owned ? "Yes" : "No"}

                                                </div>
                                            </td>
                                            <td class="px-10 py-4">
                                                <a class="flex items-center">
                                                    {moment(item.created_on).format("YYYY-MM-DD hh:mm")}

                                                </a>
                                            </td>
                                            <td class="flex items-center px-6 py-4 space-x-3">
                                                <button  class="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={((e) => {

                                                    setIsEditModalOpen(true)
                                                    setSelectedVideo(item)

                                                })}>Edit</button>
                                                <button  class="font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {

                                                    setIsDeleteModalOpen(true)
                                                    setSelectedVideo(item)

                                                })}>Reject</button>

                                            </td>

                                        </tr>

                                    )
                                    ) : null
                            }

                            {

                                currentTab == 1 ?
                                    approvedVideos.map((item) => (

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.user ? item.user.full_name : ""}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center underline text-blue-800">
                                                    {item.video_link}

                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.is_owned ? "Yes" : "No"}

                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <a class="flex items-center">
                                                    {moment(item.created_on).format("YYYY-MM-DD hh:mm")}

                                                </a>
                                            </td>
                                            <td class="flex items-center px-6 py-4 space-x-3">
                                                <button  class="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={((e) => {

                                                    setIsEditModalOpen(true)
                                                    setSelectedVideo(item)

                                                })}>Edit</button>
                                                <button  class="font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {

                                                    setIsDeleteModalOpen(true)
                                                    setSelectedVideo(item)

                                                })}>Remove</button>
                                            </td>

                                        </tr>
                                    )
                                    ) : null
                            }

                            {
                                currentTab == 2 ?
                                    editingVideos.map((item) => (

                                        <tr class="bg-white border-b  hover:bg-gray-50">
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.user ? item.user.full_name : ""}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center underline text-blue-800">
                                                    {item.video_link}

                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <div class="flex items-center">
                                                    {item.is_owned ? "Yes" : "No"}

                                                </div>
                                            </td>
                                            <td class="px-6 py-4">
                                                <a class="flex items-center">
                                                    {moment(item.created_on).format("YYYY-MM-DD hh:mm")}

                                                </a>
                                            </td>
                                            <td class="flex items-center px-6 py-4 space-x-3">
                                                <button  class="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={((e) => {

                                                    setIsEditModalOpen(true)
                                                    setSelectedVideo(item)

                                                })}>Edit</button>
                                                <button  class="font-medium text-red-600 dark:text-red-350 hover:underline" onClick={((e) => {

                                                    setIsDeleteModalOpen(true)
                                                    setSelectedVideo(item)

                                                })}>Remove</button>
                                            </td>

                                        </tr>
                                    )
                                    ) : null
                            }




                        </tbody>
                    </table>

                </div>


            </div>


            <DeleteModal
                is_reject_modal={true}
                open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen} deleteFunc={deleteItem}
                setRejectionReason={((e) => {
                    if (e != "")
                        setRejection(e)
                })}
            />
            <EditVideoModal
                user={user}
                submitNote={submitNote}
                setSelectedVideo={((e) => { setSelectedVideo(e) })}
                open={isEditModalOpen} setOpen={setIsEditModalOpen} editFunc={editItem} selectedVideo={selectedVideo}


                setFacebookVideoId={setFacebookVideoId}
                setFacebookPageName={setFacebookPageName}
                setVideoCategory={setVideoCategory}


                setEditedVideo={((e) => {
                    setSelectedVideo((prevState) => ({
                        ...prevState,
                        edited_video_link: e,
                    }));
                })}

                setB2BEditedVideo={((e) => {
                    setSelectedVideo((prevState) => ({
                        ...prevState,
                        b2b_video_link: e,
                    }));
                })}



                

                vendors={vendors}
                setSelectedVendor={setSelectedVendor}

                

                setVideoDescription={((e) => {
                    setSelectedVideo((prevState) => ({
                        ...prevState,
                        description: e,
                    }));
                })}


            />
        </Layout>
    );
}

export default Videos;
