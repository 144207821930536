import Layout from "../components/Layout"
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios';
import * as moment from 'moment';
import { useLocation } from "react-router-dom";

function Profile() {
    const navigate = useNavigate()
    const [details, setDetails] = useState({ videos: [], userInfo: {} })
    const [wallet, setWallet] = useState(0)
    // const location = useLocation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('user_id');




    useEffect(() => {
        getTelewelerDetails(userId)
    }, []);



    async function getTelewelerDetails(userId) {
        if (userId) {
            const response = await axios.get(`https://api.telewee.com/users/${userId}/videos`);

// alert(`https://api.telewee.com/users/${userId}/videos`)
            setDetails(response.data)

            let local_wallet = 0
            response.data.videos.map((video) => {
                local_wallet += video.b2b_user_earning
                local_wallet += video.user_earning
            })
            setWallet(local_wallet)

        }
    }


    return (
        <Layout>
            <div class="pl-4 pr-4 sm:ml-64">

                <h1 class="text-lg font-bold text-black-900 mt-5">User Profile</h1>

                <div class="max-w-full mx-4 py-6 sm:mx-auto">
                    <div class="sm:flex sm:space-x-4">
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Full Name</h3>
                                        <p class="text-xl  text-gray-800 text-wrap">{details?.userInfo?.full_name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Wallet</h3>
                                        <p class="text-xl  text-gray-800 text-wrap">{wallet}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Account Created On:</h3>
                                        <p class="text-xl  text-gray-800 text-wrap">{details?.userInfo?.created_on}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Total Videos Uploaded</h3>
                                        <p class="text-xl  text-gray-800 text-wrap">{details?.videos?.length}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Email</h3>
                                        <p class="text-xl  text-gray-800">{details?.userInfo?.email_address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Phone</h3>
                                        <p class="text-xl  text-gray-800">{details?.userInfo?.phone_number}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                            <div class="bg-white p-5">
                                <div class="sm:flex sm:items-start">
                                    <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Documents</h3>
                                        <a class="text-xl  text-blue-800 underline">{details?.userInfo?.document ? details?.userInfo?.document : "N/A"}</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            details.userInfo?.paypal_email ?
                                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                                    <div class="bg-white p-5">
                                        <div class="sm:flex sm:items-start">
                                            <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                                <h3 class="text-sm leading-6 font-medium text-gray-500">Paypal Account</h3>
                                                <a class="text-xl  text-blue-800 underline">{details.userInfo?.paypal_email}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }


                        {
                            details.userInfo?.iban_data ?
                                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                                    <div class="bg-white p-5">
                                        <div class="sm:flex sm:items-start">
                                            <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                                <h3 class="text-sm leading-6 font-medium text-gray-500">Bank Details</h3>
                                                <a class="text-xl  text-gray-800">IBAN: {details.userInfo?.iban_data?.iban}  </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }

                    </div>
                </div>











                <div class="relative overflow-x-auto">


                    {

                        details.videos.map((item) => (
                            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8 ">
                                <div class="bg-white p-1">
                                    <p className="text-gray-500 my-1">Date: {moment(item.created_on).format("yyyy-MM-DD hh:mm")}</p>
                                    <p className="text-gray-500 my-1">Description: {item.description}</p>
                                    <p className="text-gray-500 my-1 font-bold">Status: {item.status}</p>
                                    
                                    <p className="text-green-700 my-1">Total Revenue(B2B+B2C): {parseFloat(parseFloat(item.b2b_total_earning)+ parseFloat(item.total_earning)).toFixed(2)   }</p>
                                    <p className="text-green-700 my-1">Teleweeler Revenue: {parseFloat(parseFloat(item.b2b_user_earning)+parseFloat(item.user_earning)).toFixed(2)}</p>
                                   
                                    <video
                                        height="100"
                                        width="200"
                                        controls
                                        src={item.video_link}
                                        className="w-200 h-200"
                                    />

                                    {/* <div class="sm:flex sm:items-start"> */}
                                    {/* <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 class="text-sm leading-6 font-medium text-gray-500">Full Name</h3>
                                        <p class="text-xl  text-gray-800">{details?.userInfo?.full_name}</p>
                                    </div> */}
                                    {/* </div> */}
                                </div>
                            </div>

                        ))

                    }






                    {/* <table class="w-full text-sm text-left text-gray-500">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    User
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Video
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Description
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Owned by User
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Date
                                </th>
                          

                            </tr>
                        </thead>
                        <tbody>
                            {

                                details.videos.map((item) => (

                                    <tr class="bg-white border-b  hover:bg-gray-50">
                                        <td class="px-6 py-4">
                                            <div class="flex items-center">

                                                {details.userInfo?.full_name}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center underline text-blue-800">
                                                {item.video_link}

                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <div class="flex items-center ">
                                                {item.description}
                                            </div>
                                        </td>

                                        <td class="px-6 py-4">
                                            <div class="flex items-center">
                                                {item.is_owned ? "Yes" : "No"}

                                            </div>
                                        </td>
                                        <td class="px-6 py-4">
                                            <a class="flex items-center">
                                                {item.created_on}

                                            </a>
                                        </td>
                                       

                                    </tr>

                                ))


                            }




                        </tbody>
                    </table> */}

                </div>


            </div>

        </Layout>
    );
}

export default Profile;
