import { Fragment, useRef,useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { ExclamationIcon } from '@heroicons/react/outline'
import AWS from "aws-sdk";
import Loader from "../../components/Loader/index";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

AWS.config.update({
  accessKeyId: 'AKIA2O26TDY7DWDGNT4Z',
  secretAccessKey: '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',
  region: 'eu-north-1'
});

export default function ManualUploadModal({
  open,
  setOpen,
  currentVideo,
  setCurrentVideo,
  submitVideo,
}) {
  const cancelButtonRef = useRef(null)
  const [showLoader, setShowLoader] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(null);
  const [categories, setCategories] = useState([
    "Kids",
    "Cooking",
    "Fails And Funnies",
    "News",
    "Travel",
    "Podcast",
    "Digital creator",
    "Entertainment",
    "Magic",
    "Challenge",
    "Sport Culture",
    "Islamic",
    "Motivation",
    "Animals",
    "Music",
    "couples",
  ])

  function generateRandomChars() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  const handleFileChange = async (event) => {

    setShowLoader(true);

    const file = event.target.files[0];
    // S3 Bucket Name
    const S3_BUCKET = "teleweevideos";

    // S3 Region
    const REGION = "eu-north-1";


    var extension = file.name.split('.').pop();
    let randomName = generateRandomChars() + "." + extension


    // S3 Credentials
    AWS.config.update({
      accessKeyId: 'AKIA2O26TDY7DWDGNT4Z',
      secretAccessKey: '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',
      region: 'eu-north-1'

    });
    const s3 = new AWS.S3();

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: randomName,
      Body: file,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
        setUploadPercentage(parseInt((evt.loaded * 100) / evt.total) + "%")
      })
      .promise();

    await upload.then((err, data) => {
      // Fille successfully uploaded
      console.log(file)
      console.log(data)
      console.log("https://teleweevideos.s3.eu-north-1.amazonaws.com/" + randomName)
      
      setCurrentVideo((prevState) => ({
        ...prevState,
        "video_link": "https://teleweevideos.s3.eu-north-1.amazonaws.com/" + randomName,
    }));

      setShowLoader(false);
      setUploadPercentage(null)

    }).catch(err => {
      setUploadPercentage(null)
      setShowLoader(false);
      alert("Something went wrong!!")
    })

  };



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Publish Manual Video
                      
                    </Dialog.Title>
                    <div className="mt-2">
                      {/* <p className="text-sm text-gray-500">
                        Are you sure?
                      </p> */}


<div className='mb-2'>
                            <label for="email" class="block mb-2 text-sm  text-gray-500">Assign Page</label>
                            <select
                              onChange={((e) =>{
                                setCurrentVideo((prevState) => ({
                                    ...prevState,
                                    "facebook_channel": e.target.value,
                                }));
                              })}
                              id="country"
                              name="country"
                              className="mt-2 block w-52 rounded-md border-0 px-2 py-2 text-gray-900 mr-auto ring-1 ring-inset ring-gray-300"
                            >
                              <option>Select Page</option>
                              <option>سنجن الكوري</option>
                              <option>آيات</option>
                              <option>عبسي</option>
                              <option>سالي العوضي</option>
                              <option>قهوة أبو صالح</option>
                              <option>محمد أبو شقرة</option>
                              <option>Telewee</option>
                              <option>الشعراوي Al-Shaarawy</option>
                              <option>ليمار ميوزك</option>
                              <option>مشاهير 5 نجوم</option>
                              <option>نهفات TV</option>
                              <option>مكس - Mix</option>
                              <option>ميتو الشامسي</option>
                            </select>

                        </div> 




                        <div>
                          <label for="email" class="block mb-2 text-sm  text-gray-500">Assign Category</label>
                          <select
                             onChange={((e) =>{
                                setCurrentVideo((prevState) => ({
                                    ...prevState,
                                    "category": e.target.value,
                                }));
                              })}
                            // onChange={((e) => setVideoCategory(e.target.value))}
                            id="country"
                            name="country"
                            className="mt-2 block w-52 rounded-md border-0 px-2 py-2 text-gray-900 mr-auto ring-1 ring-inset ring-gray-300"
                          >

                            <option>Select Category</option>
                            {
                              categories.map((key) => (
                                <option value={key}>{key}</option>
                              ))
                            }


                          </select>
                        </div> 





                        <div>
                          <label for="email" class="mt-4 block mb-2 text-sm  text-gray-500">Teleweeler ID</label>
                          <input 
                                value={currentVideo?.user?currentVideo?.user:""}
                          onChange={((e) => { 
                                setCurrentVideo((prevState) => ({
                                    ...prevState,
                                    "user": e.target.value,
                                }))
                            // setFacebookVideoId(e.target.value) 
                            })} type="text" name="text" id="text" placeholder="Enter a Valid ID" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg   block w-full p-2.5 " required />
                        </div>

                        <div>
                          <label for="email" class="mt-4 block mb-2 text-sm  text-gray-500">Facebook Video ID (after publishing)</label>
                          <input onChange={((e) => { 
                                setCurrentVideo((prevState) => ({
                                    ...prevState,
                                    "facebook_video_id": e.target.value,
                                }));
                            // setFacebookVideoId(e.target.value) 
                            })} type="text" name="text" id="text" placeholder="FACEBOOK VIDEO ID ######" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg   block w-full p-2.5 " required />
                        </div>











                    <div>
                      <div class="flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4 mt-4">

                        <div class="text-center">

                          {showLoader ? (
                            <div>
                              <Loader percentage={uploadPercentage} />
                            </div>
                          ) : null}

                          {currentVideo?.video_link != "" && currentVideo?.video_link != undefined ? (
                            <div>

                           
                              <video
                                className="w-[300px] h-[300px]"
                                src={currentVideo?.video_link}
                                autoPlay={false}
                                controls
                              />
                            </div>
                          ) : (
                            <div>
                              <svg
                                class="mx-auto h-12 w-12 text-gray-300"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <div class="mt-4 flex text-sm leading-6 text-gray-600">
                                <label
                                  for="file-upload"
                                  class="relative cursor-pointer mx-auto rounded-md bg-white font-semibold text-indeigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 "
                                >
                                  <span>Upload Edited Video</span>
                                  <input
                                    onChange={handleFileChange}
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    accept="video/*"
                                    class="sr-only"
                                  />
                                </label>
                              </div>
                              <p class="text-xs leading-5 text-gray-600">
                                format
                              </p>
                            </div>
                          )} 
                        </div>
                      </div>
                    </div> 


                    

                    </div>
                  
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    submitVideo()
                  }}
                >
                  Publish
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}